import React from 'react'
import { Link } from 'react-router-dom'


function NotFoundPage() {
  return (
    <>
      <h1>404</h1>
      <h2>Woops!</h2>
      <p>Looks like you missed the crit! Here, let me apply div for you: </p>
      <Link to="/" className="btn-primary btn mt-3">Home</Link>
    </>
  )
}

export default NotFoundPage
