import { useState } from 'react'
import { WeaponModel } from '../models/WeaponModel'

const weaponTypes = {
  INSTANT:"Instant Damage",
  EXPLOSION:"Explosion Damage",
  DAMAGE_OVER_TIME:"Damage Over Time"
}

/**
 * Used to create, edit, update, etc a weapon profile.
 */
function useWeaponProfile() {
  const [weaponModel, setWeaponModel] = useState({
    name: '',
    type: weaponTypes.INSTANT,
    reloadData: [0, 0],
    shotData: [0, 0],
    magSize: 0,
    instantDamage: 0
  })

  function handleProfileName(name) {
    setWeaponModel({
      ...weaponModel,
      name:name
    })
  }

  function handleType(type) {
    setWeaponModel({
      ...weaponModel,
      type: type
    })
  }

  function handleReloadStart(reloadStart) {
    let newData = [null, null]
    if (Array.isArray(weaponModel.reloadData)) {
      newData[1] = weaponModel.reloadData[1]
    }
    newData[0] = reloadStart
    let model = weaponModel
    model.reloadData = newData
    setWeaponModel({
      ...weaponModel,
      reloadData: newData
    })
  }

  function handleReloadEnd(reloadEnd) {
    let newData = [null, null]
    if (Array.isArray(weaponModel.reloadData)) {
      newData[0] = weaponModel.reloadData[0]
    }
    newData[1] = reloadEnd
    setWeaponModel({
      ...weaponModel,
      reloadData: newData
    })
  }

  function handleShotOne(shotOneTime) {
    let newData = [null, null]
    if (Array.isArray(weaponModel.shotData)) {
      newData[1] = weaponModel.shotData[1]
    }
    newData[0] = shotOneTime
    setWeaponModel({
      ...weaponModel,
      shotData: newData
    })
  }

  function handleShotTwo(shotTwoTime) {
    let newData = [null, null]
    if (Array.isArray(weaponModel.shotData)) {
      newData[0] = weaponModel.shotData[0]
    }
    newData[1] = shotTwoTime
    setWeaponModel({
      ...weaponModel,
      shotData: newData
    })
  }

  function handleExplosionDamage(explosionDamage) {
    setWeaponModel({
      ...weaponModel,
      explosionDamage: explosionDamage
    })
  }

  function handleImpactDamage(impactDamage) {
    setWeaponModel({
      ...weaponModel,
      impactDamage: impactDamage
    })
  }

  function handleInstantDamage(instantDamage) {
    setWeaponModel({
      ...weaponModel,
      instantDamage: instantDamage
    })
  }

  function handleMagSize(magSize) {
    setWeaponModel({
      ...weaponModel,
      magSize: magSize
    })
  }

  /**
   * Validates the given weapon profile, to be used before saving. Returns true if valid, a list of
   * errors if not.
   */
  function validate() {
    let model = WeaponModel.createFromForm(weaponModel)
    let errors = []
    // Check reload times are in order if it is an array
    if (Array.isArray(model.reloadData) && model.reloadData[1] <= model.reloadData[0]) {
      errors.push('Reload time 2 must occur after reload time 1. If the weapon does not reload, use a high mag size and set the reload time to arbitrary values')
    }
    // Check shot times are in order if it is an array
    if (Array.isArray(model.shotData) && model.shotData[1] <= model.shotData[0]) {
      errors.push('Shot time 2 must occur after shot time 1')
    }
    // Check if shot time is positive if it isn't an array
    if (!Array.isArray(model.shotData) && model.shotData <= 0) {
      errors.push('Shot time/rpm must be greater than 0')
    }
    // Check if damage is positive
    if (model.damage() <= 0) {
      errors.push('Weapon damage must be positive')
    }
    // Check if name is defined
    if (typeof(model.name) !== 'string' || model.name.length <= 0) {
      errors.push('Weapon name must be defined')
    }
    // Check if mag size is positive
    if (model.magSize <= 0) {
      errors.push("Mag size cannot be 0. If the gun doesn't reload, give a very high value")
    }

    // Check if valid
    if (errors.length > 0) {
      return errors
    } else {
      return true
    }
  }

  function handleSave() {
    // Create the weapon model to save
    let model = WeaponModel.createFromForm(weaponModel)
    model.save()
    return model
  }

  return {
    weaponModel,
    setWeaponModel,
    handleProfileName,
    handleType,
    handleReloadStart,
    handleReloadEnd,
    handleExplosionDamage,
    handleImpactDamage,
    handleInstantDamage,
    handleShotOne,
    handleShotTwo,
    handleMagSize,
    handleSave,
    validate
  }
}

export default useWeaponProfile
export { weaponTypes }
