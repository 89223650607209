import React from 'react'

import DamageGraph from '../components/DamageGraph'
import WeaponProfilePicker from '../components/WeaponProfilePicker'
import useWeaponGraphing from '../hooks/useWeaponGraphing'
import { WeaponModel } from '../models/WeaponModel'
import About from './About'

function WeaponGraphing() {
  const {
    weaponProfiles,
    resetWeaponProfiles,
    addProfile
  } = useWeaponGraphing()

  /**
   * This function will forward the user to the about page so they can learn about the app on first
   * visit. The user is new if they have not created a weapon profile, but also it will not forward
   * them if someone sent a shared link that contains weapon data in the params so they can see the
   * shared data.
   */
  function isNewUser() {
    // Check if there are weapon profiles in localstorage
    const hasWeaponModels = WeaponModel.getFromLocalStorage().length > 0

    // Check to make sure there are no weapon profiles added in the weapon profile param from a
    // shared link
    const hasProfiles = weaponProfiles && weaponProfiles.length > 0

    // If both these are false, then the about page should be shown to the new user
    return (!hasProfiles && !hasWeaponModels)
  }

  // Show about if this is a new user instead, but pretend it is the home page
  if (isNewUser()) {
    return (
      <About />
    )
  }

  return (
    <>
      <h1>Weapon Testing</h1>
      <h2>Select Weapon</h2>
      <WeaponProfilePicker buttonText="Add to Graph" buttonOnClick={addProfile} />
      <div className="mt-3">
        <DamageGraph weaponProfiles={weaponProfiles} />
      </div>
      <div className="mt-2">
        <button className="btn btn-primary mx-1" onClick={() => {
          navigator.clipboard.writeText(window.location.href).then(
            alert('copied url to clipboard')
          ).catch((e) => {
            alert('error copying share link')
          })
        }}><i className='bx bx-share-alt' /> Share</button>
        <button className="btn btn-danger mx-1" onClick={resetWeaponProfiles}>Reset Graph</button>
      </div>
    </>
  )
}

export default WeaponGraphing
