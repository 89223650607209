import React, { useEffect } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent'
import ReactGA from 'react-ga4'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

import './custom.scss'
import './App.css'
import Contact from './pages/Contact'
import WeaponProfileManager from './pages/WeaponProfileManager'
import WeaponGraphing from './pages/WeaponGraphing'
import NotFoundPage from './pages/NotFoundPage'
import ErrorPage from './pages/ErrorPage'
import About from './pages/About'
import Layout from './components/Layout'

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <>
        <Layout />
      </>,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <WeaponGraphing />
        },
        {
          path: "/weapons",
          element: <WeaponProfileManager />
        },
        {
          path: "/contact",
          element: <Contact />
        },
        {
          path: "/about",
          element: <About />
        },
        {
          path: "*",
          element: <NotFoundPage />
        }
      ]
    }
  ])

  function handleAcceptCookie() {
    // Only run in production and when there is a google analytics id present
    if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
    } else {
      console.log(`did not start google analytics, node_env: ${process.env.NODE_ENV} and ga: ${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`)
    }
  }

  useEffect(() => {
    if (getCookieConsentValue() === 'true') {
      handleAcceptCookie()
    }
  }, [])

  return (
    <>
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
      <CookieConsent enableDeclineButton onAccept={handleAcceptCookie}>
        Hit the Crit uses cookies to enhance the user experience.
      </CookieConsent>
    </>
  );
}

export default App;
