import useQueryState from '../hooks/useQueryState';

function useWeaponGraphing() {
  const [weaponProfiles, setWeaponProfiles] = useQueryState("weaponProfiles", {isJSON: true})

  function addProfile(newProfile) {
    // If this is first one the array won't exist yet
    if (!weaponProfiles) {
      setWeaponProfiles([newProfile])
    } else {
      setWeaponProfiles([
        ...weaponProfiles,
        newProfile
      ])
    }
  }

  function resetWeaponProfiles() {
    setWeaponProfiles(undefined)
  }

  return {
    weaponProfiles,
    setWeaponProfiles,
    resetWeaponProfiles,
    addProfile
  }
}

export default useWeaponGraphing
