import React, { useState } from 'react'
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers'
import { Line } from 'react-chartjs-2'
import { CrosshairPlugin } from 'chartjs-plugin-crosshair'
import PropTypes from 'prop-types'

import { WeaponModel } from '../models/WeaponModel'
import DamageDisplay from './DamageDisplay'

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  CrosshairPlugin,
)

ChartJS.defaults.color = "#fff"

const lineColors = [
  '#FDB833',
  '#6290C8',
  '#57CC99',
  '#EE4266'
]


function DamageGraph(props) {
  const [hoveredData, setHoveredData] = useState({
    data: [],
    time: 0
  })

  const options = {
    onHover: (e) => {
      const canvasPosition = getRelativePosition(e)
      const chart = canvasPosition.chart
      const dataPosition = [chart.scales.x.getValueForPixel(canvasPosition.x), chart.scales.y.getValueForPixel(canvasPosition.y)]
      // Loop datasets and find the closest value based on x value
      const leftmostPoints = [] // Key is name of dataset, value is position left of cursor
      chart.data.datasets.forEach((dataset, index) => {
        for (let i = 0; i < dataset.data.length; i++) {
          if (dataset.data[i].x < dataPosition[0]) {
            leftmostPoints[index] = {name: dataset.label, damage: dataset.data[i].y, color: dataset.borderColor}
          } else {
            break
          }
        }
      })
      const newHoveredData = {data: leftmostPoints, time: dataPosition[0]}
      // Limit the re-renders by only writing when the time changes
      if (newHoveredData.time !== hoveredData.time) {
        setHoveredData(newHoveredData)
      }
    },
    plugins: {
      title: {text: "HitTheCrit.com - Maximize your DPS", font: {size: 18}, display: true},
      tooltip: {enabled: false}, // These are handled externally now
      crosshair: { color: "#fdb833" }
    },
    scales: {
      y: {
        title: {display: true, text: 'Damage', font: {size: 16}},
        min: 0,
        type: 'linear',
      },
      x: {
        title: {display: true, text: 'Time (s)', font: {size: 16}},
        min: 0,
        type: 'linear',
      }
    },
  }

  /**
   * Creates a data set for an individual weapon model
   */
  function generateDataSet(weaponModel, color=lineColors[0], maxTime=30) {
    if (weaponModel === undefined || weaponModel === null) {
      return []
    }
    let weaponData = []
    let damage = 0
    let time = 0
    let shot = 0
    while (time <= maxTime) {
      shot = shot + 1
      damage = damage + weaponModel.damage()
      weaponData.push({x: time, y: damage})
      if (shot >= weaponModel.magSize && weaponModel.reloadTime() > 0) {
        // Do reload
        time = time + weaponModel.reloadTime()
        shot = 0
      } else {
        // Increment time for next shot
        time = time + weaponModel.timeBetweenShots()
      }
    }

    return {
      label: weaponModel.name,
      data: weaponData,
      stepped: true,
      borderColor: color,
      backgroundColor: color,
    }
  }

  function generateAllDataSets(weaponProfiles, maxtime=30) {
    let datasets = []
    // Return early if the weapon profiles are undefined
    if (weaponProfiles === undefined) {
      return datasets
    }
    // Setup color incrementation
    let currentColor = 0
    const getNextColor = () => {
      const color = lineColors[currentColor]
      currentColor++
      if (currentColor >= lineColors.length) {
        currentColor = 0
      }
      return color
    }
    weaponProfiles.forEach(weaponProfile => datasets.push(generateDataSet(WeaponModel.createFromForm(weaponProfile), getNextColor())))
    return datasets
  }

  return (
    <div className="row">
      <div className="col-lg-9">
        <Line className="chart" options={options} data={{
          datasets: generateAllDataSets(props.weaponProfiles)
        }} />
      </div>
      <div className="col-sm-3">
        <DamageDisplay data={hoveredData.data} time={hoveredData.time} />
      </div>
    </div>
  )
}

DamageGraph.propTypes = {
  weaponProfiles: PropTypes.arrayOf(PropTypes.object)
}

export default DamageGraph
