import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import useWeaponProfilePicker from '../hooks/useWeaponProfilePicker'


function WeaponProfilePicker(props) {
  const {
    activeModelName,
    handleWeaponModelName,
    weaponModels,
    deleteActiveModel,
    validateActiveModel,
    getActiveModel
  } = useWeaponProfilePicker()

  // Set defaults
  const showDeleteButton = props.showDeleteButton === undefined ? false : props.showDeleteButton
  const hideOnNoModels = props.hideOnNoModels === undefined ? false : props.hideOnNoModels

  if (weaponModels.length === 0) {
    return (
      <div className={"row" + (hideOnNoModels ? " d-none" : "")}>
        <strong className="d-inline"><p>You haven't created any Weapon Profiles yet!</p></strong>
        <Link to="/weapons" className="d-inline">
          <button className="btn btn-primary" type="button">Create New Weapon Profile</button>
        </Link>
      </div>
    )
  } else {
    return (
      <div className="input-group m-2">
        <span className="input-group-text">Weapon</span>
        <input
          type="text"
          className="form-control form-control-sm"
          list="weapon-model-names"
          value={activeModelName}
          onChange={(e) => handleWeaponModelName(e.target.value)}
        />
        <datalist id="weapon-model-names">
          {weaponModels.map((model) => {
            return (
              <option key={model.name} value={model.name}>{model.name}</option>
            )
          })}
        </datalist>
        <button className="btn btn-primary" type="button" onClick={() => {props.buttonOnClick(getActiveModel())}} disabled={!validateActiveModel()}>
          {props.buttonText}
        </button>
        {showDeleteButton ? <button className={"btn btn-danger" + (showDeleteButton ? "" : " d-none")} onClick={(e) => {
          deleteActiveModel(e)
          alert('deleted weapon profile')
        }} type="button" disabled={!validateActiveModel()}>Delete</button> : <></>}
      </div>
    )
  }
}

WeaponProfilePicker.propTypes = {
  buttonText: PropTypes.string,
  buttonOnClick: PropTypes.func,
  hideOnNoModels: PropTypes.bool,
  showDeleteButton: PropTypes.bool
}

export default WeaponProfilePicker
