import { useCallback } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import qs from "qs"

function useQueryState(query, config={isJSON: false, default: undefined}) {
  const location = useLocation()
  const navigate = useNavigate()

  const setQuery = useCallback(value => {
      const existingQueries = qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })

      if (config.isJSON) {
        value = JSON.stringify(value)
      }

      const queryString = qs.stringify(
        { ...existingQueries, [query]: value },
        { skipNulls: true }
      )

      navigate(`${location.pathname}?${queryString}`)
    },
    [navigate, location, query, config.isJSON]
  )

  let resultValue = qs.parse(location.search, { ignoreQueryPrefix: true })[query]
  if (config.isJSON && resultValue) {
    resultValue = JSON.parse(resultValue)
  }

  return [
    resultValue,
    setQuery,
  ]
}

export default useQueryState
