import React from 'react'
import { Link } from 'react-router-dom'


function ErrorPage() {
  return (
    <div className="container text-center mt-5">
      <h1>500</h1>
      <h2>Woops!</h2>
      <p>Looks like we missed on that one! If this problem persists, please <Link to="/contact">Contact Us</Link></p>
      <Link to="/" className="btn-primary btn mt-3">Home</Link>
    </div>
  )
}

export default ErrorPage