import { useState } from 'react'

import { WeaponModel } from '../models/WeaponModel'


function useWeaponProfilePicker() {
  let weaponModels = WeaponModel.getFromLocalStorage()
  const [activeModelName, setActiveModelName] = useState("")

  function getActiveModel() {
    let model = weaponModels.find(element => element.name === activeModelName)
    if (model === undefined) {
      return null
    } else {
      return model
    }
  }

  function validateActiveModel() {
    return getActiveModel() !== null
  }

  function handleWeaponModelName(weaponModelName) {
    setActiveModelName(weaponModelName)
  }

  function deleteActiveModel(event) {
    WeaponModel.deleteByName(activeModelName)
    // Re-acquire the weapon models
    weaponModels = WeaponModel.getFromLocalStorage()
    setActiveModelName("")
  }

  return {
    activeModelName,
    handleWeaponModelName,
    weaponModels,
    deleteActiveModel,
    validateActiveModel,
    getActiveModel
  }
}

export default useWeaponProfilePicker
