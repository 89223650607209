import { weaponTypes } from "../hooks/useWeaponProfiler"

class WeaponModel {
  constructor(type, name, reloadData, shotData, magSize) {
    this.name = name
    this.type = type
    // Reload data can be provided as 2 times in s and calculated afterwards, or as 1 value
    this.reloadData = reloadData
    // Shot data can be provided as 2 times in s and calculated afterwards, or as 1 value
    this.shotData = shotData
    this.magSize = magSize
  }

  reloadTime = () => {
    if (this.reloadData === undefined) {
      return null
    } else if (Array.isArray(this.reloadData) && this.reloadData.length === 2) {
      return this.reloadData[1] - this.reloadData[0]
    } else if (typeof(this.reloadData) === 'number') {
      return this.reloadData
    } else {
      return null
    }
  }

  timeBetweenShots = () => {
    if (this.shotData === undefined) {
      return null
    } else if (Array.isArray(this.shotData) && this.shotData.length === 2) {
      return this.shotData[1] - this.shotData[0]
    } else if (typeof(this.shotData) === 'number') {
      // This is assumed to be an rpm, so need to change this to time between shots
      return 60 / this.shotData
    } else {
      return null
    }
  }

  static load = (data) => {
    let model
    if (data.type === weaponTypes.INSTANT) {
      model = InstantDamageModel
    } else if (data.type === weaponTypes.EXPLOSION) {
      model = ExplosionDamageModel
    } else if (data.type === weaponTypes.DAMAGE_OVER_TIME) {
      console.warn('todo instant damage model not implemented')
      model = InstantDamageModel
    } else {
      console.log('default to instant model')
      model = InstantDamageModel
    }
    return new model(data.id, data)
  }

  dump = () => {
    return JSON.stringify(this)
  }

  toJSON = () => {
    return {
      name: this.name,
      type: this.type,
      reloadData: this.reloadData,
      shotData: this.shotData,
      magSize: this.magSize
    }
  }

  static LocalStorageKey = 'weaponProfiles'

  static getFromLocalStorage(storageKey = WeaponModel.LocalStorageKey) {
    // Get data from local storage
    let data = JSON.parse(localStorage.getItem(storageKey))
    // If the key doesn't exist yet, just return an empty list
    if (data === null) {
      return []
    } else {
      return data
    }
  }

  validate() {
    // If the name is undefined or empty
    if (!this.name) {
      throw new Error('name is not defined')
    }
  }

  save = () => {
    // Validate
    this.validate()
    // Load from local storage
    let weaponData = WeaponModel.getFromLocalStorage()
    // If null, the weapon data needs to be initialized as an empty array
    if (weaponData === null) {
      weaponData = [
        this
      ]
    } else {
      // Check to make sure the element with this id doesn't already exist
      let index = weaponData.findIndex(o => o.name === this.name)
      if (index === -1) {
        weaponData.push(this)
      } else {
        weaponData[index] = this
      }
    }

    localStorage.setItem(WeaponModel.LocalStorageKey, JSON.stringify(weaponData))
  }

  delete() {
    return WeaponModel.deleteByName(this.name)
  }

  static deleteByName = (weaponName) => {
    let weaponData = WeaponModel.getFromLocalStorage()
    let index = weaponData.findIndex(o => o.name === weaponName)
    if (index === -1) {
      return null
    } else {
      const weaponToDelete = weaponData[index]
      weaponData.splice(index, 1)
      localStorage.setItem(WeaponModel.LocalStorageKey, JSON.stringify(weaponData))
      return weaponToDelete
    }
  }

  static createFromForm = (weaponData) => {
    let args = [weaponData.type, weaponData.name, weaponData.reloadData, weaponData.shotData, weaponData.magSize]
    if (weaponData.type === weaponTypes.INSTANT) {
      args = args.concat([weaponData.instantDamage])
      return new InstantDamageModel(...args)
    } else if (weaponData.type === weaponTypes.EXPLOSION) {
      args = args.concat([weaponData.impactDamage, weaponData.explosionDamage])
      return new ExplosionDamageModel(...args)
    }
  }
}

class InstantDamageModel extends WeaponModel {
  constructor(type, name, reloadData, shotData, magSize, instantDamage) {
    super(type, name, reloadData, shotData, magSize)
    this.instantDamage = instantDamage
  }

  damage = () => {
    return this.instantDamage
  }

  toJSON = () => {
    return {
      name: this.name,
      type: this.type,
      reloadData: this.reloadData,
      shotData: this.shotData,
      magSize: this.magSize,
      instantDamage: this.instantDamage
    }
  }
}

class ExplosionDamageModel extends WeaponModel {
  constructor(type, name, reloadData, shotData, magSize, impactDamage, explosionDamage) {
    super(type, name, reloadData, shotData, magSize)
    this.impactDamage = impactDamage
    this.explosionDamage = explosionDamage
  }

  damage = () => {
    return this.impactDamage + this.explosionDamage
  }

  toJSON = () => {
    return {
      name: this.name,
      type: this.type,
      reloadData: this.reloadData,
      shotData: this.shotData,
      magSize: this.magSize,
      impactDamage: this.impactDamage,
      explosionDamage: this.explosionDamage
    }
  }
}

export {
  WeaponModel,
  InstantDamageModel,
  ExplosionDamageModel
}
