import React from 'react'
import { Link } from 'react-router-dom'

import videoPlayerPicture from '../images/weaponProfiler.jpg'
import graphExamplePicture from '../images/graphExample.jpg'
import fireteamPicture from '../images/fireteam.jpg'

function About() {
  return (
    <>
      <div className="row my-3">
        <div className="col text-center">
          <h1 className="text-primary">Hit the Crit</h1>
          <h5>
            Record gameplay, build weapon profiles, and compare them to optimize your damage!
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 order-md-last my-3">
          <h4 className="text-primary">Compare your weapons</h4>
          <strong>
            Quickly compare your weapons, and see how much damage you do at
            any point of the fight.
          </strong>
          <br />
          <Link to="/weapons" className="btn-primary btn mt-3">Test your Weapons</Link>
          <a className="btn-secondary btn mx-2 mt-3" href="https://youtu.be/x9MxHtUsp-M">Learn How it Works</a>
        </div>
        <div className="col-md-7 order-md-first my-3">
          <img src={graphExamplePicture} className="about-image" alt="damage graph" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 my-3">
          <h4 className="text-primary">Built-In Video Player</h4>
          <strong>
            Use your own videos to quickly test weapon damage. Step through
            the video to find key moments in your DPS.
          </strong>
          <br />
          <Link to="/weapons" className="btn-primary btn mt-3">Try the Video Player</Link>
        </div>
        <div className="col-md-7 my-3">
          <img src={videoPlayerPicture} className="about-image float-end" alt="video player" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 order-md-last my-3">
          <h4 className="text-primary">Share Your Results</h4>
          <strong>
            You can't kill that raid boss on your own! Share your results with
            your fireteam and get that quick kill!
          </strong>
          <br />
          <Link to="/weapons" className="btn-primary btn mt-3">Start Testing to Share</Link>
        </div>
        <div className="col-md-7 order-md-first my-3">
          <img src={fireteamPicture} className="about-image" alt="fireteam" />
        </div>
      </div>
    </>
  )
}

export default About
