import React, {useState} from 'react'

import WeaponProfiler from '../components/WeaponProfiler'
import VideoPlayer from '../components/VideoPlayer'
import WeaponProfilePicker from '../components/WeaponProfilePicker'

function WeaponProfileManager() {
  const [weaponModel, setWeaponModel] = useState(null)
  const [videoTime, setVideoTime] = useState(null)

  return (
    <>
      <WeaponProfilePicker hideOnNoModels={true} showDeleteButton={true} buttonText='Edit' buttonOnClick={setWeaponModel} />
      <h1>Weapon Profiler</h1>
      <VideoPlayer videoTimeCallback={setVideoTime} />
      <WeaponProfiler editModel={weaponModel} videoTime={videoTime} />
    </>
  )

}

export default WeaponProfileManager
