import React from 'react'

function Contact() {
  return (
    <div className="row">
      <h1>Contact Us</h1>
      <div className="col">
        <p>
          Do you have suggestions that could help make Hit the Crit better, or have found a bug that you would like to report? Join us on Discord and reach out to an Administrator, or discuss the tool with the community!
        </p>
        <a className="btn btn-primary" href="https://discord.gg/rHDymGtm97"><i className='bx bxl-discord-alt align-middle fs-3' /> Join Discord</a>
      </div>
    </div>
  )
}

export default Contact
