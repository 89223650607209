import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import logo from '../images/hit-the-crit-horizontal.png'

function Layout() {
  return(
    <>
      <nav id="primary-nav" className="navbar navbar-expand-md navbar-dark sticky-top">
        <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          <img id="nav-logo" src={logo} alt="Thit the Crit" />
        </NavLink>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-content" aria-controls="navbar-content" aria-expanded="false" aria-label="Toggle Navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbar-content">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/weapons">
                Weapons
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about">
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact">
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      </nav>
      <div className="container mb-3">
        <Outlet />
      </div>
    </>
  )
}

export default Layout
