import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import useVideoPlayer from '../hooks/useVideoPlayer'


function VideoPlayer(props) {
  // Will hold the reference to the video element in html
  const videoElement = useRef(null)
  // The video file selector reference
  const inputOpenFileRef = useRef(null)
  // The video file to play
  const [video, setVideo] = useState(null)

  // Load all the hooks that will be used to control the video player
  const {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
    handleSmallScrub,
    resetSmallScrub,
    copyTimeToClipboard
  } = useVideoPlayer(videoElement)

  useEffect(() => {
    if (videoElement.current) {
      props.videoTimeCallback(videoElement.current.currentTime)
    }
  })

  return (
    <>
      <div className="row">
        <div className="video-player">
          <video ref={videoElement} onTimeUpdate={handleOnTimeUpdate} src={video} />
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="controls">
          {/* Play/Pause button */}
          <button id="play-btn" title="Play/Pause" onClick={togglePlay}>
              {!playerState.isPlaying ? (
                <i className="bx bx-play"></i>
              ) : (
                <i className="bx bx-pause"></i>
              )}
          </button>
          {/* Main video progress scrubber */}
          <input
            id="main-scrubber"
            className="scrubber form-range"
            type="range"
            min="-1"
            max="99"
            value={playerState.progress}
            onChange={(e) => handleVideoProgress(e)}
          />
          {/* Video velocity/speed setting */}
          <select
            id="speed-selection"
            value={playerState.speed}
            onChange={(e) => handleVideoSpeed(e)}
          >
            <option value="0.50">0.50x</option>
            <option value="1">1x</option>
            <option value="1.25">1.25x</option>
            <option value="2">2x</option>
          </select>
          {/* Mute button */}
          <button id="mute-btn" title="Mute/Unmute" onClick={toggleMute}>
            {!playerState.isMuted ? (
              <i className='bx bx-volume-full'></i>
            ) : (
              <i className="bx bxs-volume-mute"></i>
            )}
          </button>
          {/* Copy time button */}
          <button id="copy-time-btn" title="Copy current player time" onClick={copyTimeToClipboard}>
            <i className='bx bx-time-five' />
          </button>
          {/* Small scrubber for fine input */}
          <input
            id="small-scrubber"
            className="scrubber form-range"
            type="range"
            min="-1"
            max="1"
            step="any"
            value={playerState.smallScrub}
            onMouseUp={(e) => resetSmallScrub(e)}
            onInput={(e) => handleSmallScrub(e)}
          />
          {/* File input */}
          <input type="file" accept="video/*" ref={inputOpenFileRef} onChange={(e) => {setVideo(window.URL.createObjectURL(e.target.files[0]))}}/>
          <button id="file-select-button" onClick={() => inputOpenFileRef.current.click()}>
            <i className='bx bxs-videos'></i>
          </button>
        </div>
      </div>
    </>
  );
}

VideoPlayer.propTypes = {
  videoTimeCallback: PropTypes.func
}

export default VideoPlayer
