import React from 'react'
import PropTypes from 'prop-types'

function DamageDisplay(props) {
  function numberWithSpaces(num) {
    let parts = num.toString().split(".")
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    return parts.join(".")
  }

  function round(num, decimalPlaces=2) {
    const multiplier = 10 ** decimalPlaces
    return Math.round(num * multiplier) / multiplier
  }

  return (
    <>
      <h2>Comparison</h2>
      <p>Hover/tap the graph to show stats</p>
      <p><strong>Time:</strong> {round(props.time)}s</p>
      {
        props.data.sort((i1, i2) => (i1.damage < i2.damage) ? 1 : -1).map(value => {
          const name = value.name
          const damage = value.damage
          const color = value.color

          return (<div key={name} style={{color: color}}>
            <h3 style={{color: color}}>{name}:</h3>
            <p><strong>Damage:</strong> {numberWithSpaces(damage)}</p>
            <p><strong>DPS:</strong> {numberWithSpaces(round(damage / props.time))}</p>
          </div>)
        })
      }
    </>
  )
}

DamageDisplay.propTypes = {
  data: PropTypes.array,
  time: PropTypes.number
}

export default DamageDisplay
