import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import useWeaponProfiler, { weaponTypes } from "../hooks/useWeaponProfiler"

function WeaponProfiler(props) {
  const {
    weaponModel,
    handleProfileName,
    handleType,
    handleReloadStart,
    handleReloadEnd,
    handleExplosionDamage,
    handleImpactDamage,
    handleInstantDamage,
    handleShotOne,
    handleShotTwo,
    handleMagSize,
    handleSave,
    setWeaponModel,
    validate
  } = useWeaponProfiler()

  useEffect(() => {
    if (props.editModel) {
      setWeaponModel(props.editModel)
    }
  }, [props.editModel, setWeaponModel])

  let  typeSpecificFields = <>
    <div className={weaponModel.type === weaponTypes.INSTANT ? "" : "d-none"}>
      <div className="form-group">
        <label htmlFor="instant-damage">Damage</label>
        <input id="instant-damage" className="form-control" type="number" name="instant-damage" placeholder="Damage" value={weaponModel.instantDamage} onChange={(e) => handleInstantDamage(Number(e.target.value))} />
        <small id="instant-damage-help" className="form-text text-muted">Total damage of 1 shot</small>
      </div>
    </div>
    <div className={weaponModel.type === weaponTypes.EXPLOSION ? "" : "d-none"}>
      <div className="form-group">
        <span className="input-group-text">Impact Damage</span>
        <input id="impact-damage" className="form-control" type="number" name="impact-damage" placeholder="Impact Damage" value={weaponModel.impactDamage} onChange={(e) => handleImpactDamage(Number(e.target.value))} />
        <span className="input-group-text">Explosion Damage</span>
        <input id="explosion-damage" className="form-control" type="number" name="explosion-damage" placeholder="Explosion Damage" value={weaponModel.explosionDamage} onChange={(e) => handleExplosionDamage(Number(e.target.value))} />
      </div>
    </div>
  </>

  return (
    <div className="row">
      <div className="col-12">
        <div className="accordion mb-2" id="weaponProfilerAccordian">
          {/* Instructions */}
          <div className="accordion-item mt-3">
            <h2 className="accordion-header" id="instructions-accordion-label">
              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#instructions-accordion" aria-expanded="true" aria-controls="instructions-accordion">
                Instructions
              </button>
            </h2>
            <div id="instructions-accordion" className="accordion-collapse collapse show" aria-labelledby="instructions-accordion-label">
              <div className="accordion-body">
                <p>
                  Use the below form to create a new weapon profile. Currently, mobile devices are not fully supported when creating a weapon profile. For more info, follow <a href="https://youtu.be/x9MxHtUsp-M">this tutorial on youtube.</a>
                </p>
                <ol>
                  <li>To start, above press the file select button, <i className='bx bxs-videos text-primary' />, in the video player above to open a video.</li>
                  <li>To get to key times in the video, use the second slider in the video controls to slowly adjust the video time.</li>
                  <li>Any input that has a <i className="bx bx-time-five text-primary" /> button requires a time input, and clicking the button will get the time of the video player for you.</li>
                  <li>To delete a weapon profile, select it at the top of the page and select delete.</li>
                  <li>To edit an existing profile, select it at the top of the page and select edit. This will load the weapon profile in the form below, allowing you to change and then save it.</li>
                  <li>If you encounter any issues, or have suggestions, please <Link to="/contact">Contact Us</Link></li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <form>
          {/* Weapon Name */}
          <div className="row mb-2">
            <div className="col">
              <div className="form-group">
                <label htmlFor="name">Weapon Name</label>
                <input className="form-control" type="text" name="name" id="name" placeholder="Weapon..." value={weaponModel.name} onChange={(e) => handleProfileName(e.target.value)} />
              </div>
            </div>
          </div>

          {/* Damage Type */}
          <div className="row mb-2">
            <div className="col">
              <div className="form-group">
                <div className="input-group m-2" hidden={true}> {/* Hiding until damage type is more relevant */}
                  <span className="input-group-text">Damage Type</span>
                  <select
                    aria-label="Select Weapon Type"
                    className="form-select form-control-sm"
                    defaultValue={weaponTypes.INSTANT}
                    onChange={(e) => {handleType(e.target.value)}}
                  >
                    <option value={weaponTypes.INSTANT}>{weaponTypes.INSTANT}</option>
                    <option value={weaponTypes.EXPLOSION}>{weaponTypes.EXPLOSION}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          {/* Damage Details */}
          <div className="row mb-2">
            <div className="col">
              {typeSpecificFields}
            </div>
          </div>

          {/* RPM Calculations */}
          <div className="row mb-2">
            <div className="col">
              <div className="form-group">
                <label htmlFor="shot-one-time">Shot 1 Time</label>
                <div className="input-group">
                  <input id={`shot-one-time`} className="form-control" type="number" name="shot-one-time" placeholder="Shot 1 Time" value={Array.isArray(weaponModel.shotData) ? weaponModel.shotData[0] : ""} onChange={(e) => handleShotOne(Number(e.target.value))} />
                  <button className="btn btn-secondary" type="button" onClick={() => {handleShotOne(props.videoTime)}} title="Get Video Player Time">
                    <i className='bx bx-time-five' />
                  </button>
                </div>
                <small id="shot-one-help" className="form-text text-muted">Time of first shot</small>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="shot-two-time">Shot 2 Time</label>
                <div className="input-group">
                  <input id={`shot-two-time`} className="form-control" type="number" name="shot-two-time" placeholder="Shot 2 Time" value={Array.isArray(weaponModel.shotData) ? weaponModel.shotData[1] : ""} onChange={(e) => handleShotTwo(Number(e.target.value))} />
                  <button className="btn btn-secondary" type="button" onClick={() => {handleShotTwo(props.videoTime)}} title="Get Video Player Time">
                    <i className='bx bx-time-five' />
                  </button>
                </div>
                <small id="shot-two-help" className="form-text text-muted">Time of second shot</small>
              </div>
            </div>
          </div>

          {/* Reload Details */}
          <div className="row mb-2">
            <div className="col">
              <div className="form-group">
                <label htmlFor="reload-start">Reload Start</label>
                <div className="input-group">
                  <input id={`reload-start`} className="form-control" type="number" name="reload-start" placeholder="Reload Start" value={Array.isArray(weaponModel.reloadData) ? weaponModel.reloadData[0] : ""} onChange={(e) => handleReloadStart(Number(e.target.value))} />
                  <button className="btn btn-secondary" type="button" onClick={() => {handleReloadStart(props.videoTime)}} title="Get Video Player Time">
                    <i className='bx bx-time-five' />
                  </button>
                </div>
                <small id="reload-start-help" className="form-text text-muted">The time the final shot of the first mag was fired</small>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="reload-end">Reload End</label>
                <div className="input-group">
                  <input id={`reload-end`} className="form-control" type="number" name="reload-end" placeholder="Reload End" value={Array.isArray(weaponModel.reloadData) ? weaponModel.reloadData[1] : ""} onChange={(e) => handleReloadEnd(Number(e.target.value))} />
                  <button className="btn btn-secondary" type="button" onClick={() => {handleReloadEnd(props.videoTime)}} title="Get Video Player Time">
                    <i className='bx bx-time-five' />
                  </button>
                </div>
                <small id="reload-end-help" className="form-text text-muted">The time the first shot of the second mag was fired</small>
              </div>
            </div>
          </div>

          {/* Mag Size */}
          <div className="row mb-2">
            <div className="col">
              <div className="form-group">
                <label htmlFor="mag-size">Mag Size</label>
                <input id={`mag-size`} className="form-control" type="number" name="mag-size" placeholder="Mag Size" value={weaponModel.magSize} onChange={(e) => handleMagSize(Number(e.target.value))} />
                <small id="mag-help" className="form-text text-muted">Input effective mag size, ex. add in bullets from fourth time's the charm</small>
              </div>
            </div>
          </div>
        </form>

        <button title="Save" className="btn btn-primary mx-2 mt-2 float-end" onClick={() => {
          try {
            // Validate
            const validation = validate()
            if (validation !== true) {
              alert(`Weapon profile had the following errors:\n${validation.join("\n")}`)
            } else {
              const result = handleSave()
              if (result) {
                alert('Weapon profile saved!')
              } else {
                alert('error occurred saving the weapon profile')
              }
            }
          } catch (error) {
              alert(error)
          }
        }}>Save</button>
      </div>
    </div>
  )
}

WeaponProfiler.propTypes = {
  setWeaponModelCallback: PropTypes.func,
  editModel: PropTypes.object,
  videoTime: PropTypes.number
}

export default WeaponProfiler
