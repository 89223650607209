import { useState, useEffect } from 'react';

function useVideoPlayer(videoElement) {
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    progress: 0,
    speed: 1,
    isMuted: false,
    savedTime: null,
    smallScrub: 0
  })

  const togglePlay = () => {
    setPlayerState({
      ...playerState,
      isPlaying: !playerState.isPlaying
    })
  }

  useEffect(() => {
    playerState.isPlaying
      ? videoElement.current.play()
      : videoElement.current.pause()
  }, [playerState.isPlaying, videoElement])

  const toggleMute = () => {
    setPlayerState({
      ...playerState,
      isMuted: !playerState.isMuted
    })
  }

  useEffect(() => {
    playerState.isMuted
      ? videoElement.current.muted = true
      : videoElement.current.muted = false
  }, [playerState.isMuted, videoElement])

  const handleOnTimeUpdate = () => {
    if (!videoElement.current) {return}
    const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100
    setPlayerState({
      ...playerState,
      progress
    })
  }

  const handleVideoProgress = (event) => {
    const manualChange = Number(event.target.value)
    videoElement.current.currentTime = (videoElement.current.duration / 100) * manualChange
    setPlayerState({
      ...playerState,
      progress: manualChange,
      currentTime: videoElement.current.currentTime
    })
  }

  const handleVideoSpeed = (event) => {
    const speed = Number(event.target.value)
    videoElement.current.playbackRate = speed
    setPlayerState({
      ...playerState,
      speed
    })
  }

  /**
   * @param {function} callback The callback to run after the save time is triggered, passing the
   *        current player time as an argument.
   */
  const saveTime = (callback) => {
    setPlayerState({
      ...playerState,
      savedTime: videoElement.current.currentTime
    })
    if (callback) {
      callback(videoElement.current.currentTime)
    } else {
      console.log(`No callback given for time save, but current video time is ${videoElement.current.currentTime}`)
    }
  }

  const handleSmallScrub = (event) => {
    // Get old small scrub value
    const oldScrub = playerState.smallScrub
    const manualChange = Number(event.target.value)
    // Force pause the player
    setPlayerState({
      ...playerState,
      isPlaying: false,
      smallScrub: manualChange
    })
    videoElement.current.currentTime += (manualChange - oldScrub)
  }

  const resetSmallScrub = () => {
    setPlayerState({
      ...playerState,
      smallScrub: 0,
      oldScrub: 0
    })
  }

  function copyTimeToClipboard() {
    navigator.clipboard.writeText(videoElement.current.currentTime)
  }

  function getCurrentTime() {
    return videoElement.current.currentTime
  }

  return {
    playerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
    saveTime,
    handleSmallScrub,
    resetSmallScrub,
    copyTimeToClipboard,
    getCurrentTime
  }
}

export default useVideoPlayer
